import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { selectOrganization } from 'state/auth/selectors';
import { selectProfile } from 'state/auth/selectors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import CustomSelect from 'components/CustomSelect';
import { FormControl, IconButton } from '@material-ui/core';
import {
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';

import Watchers from 'components/Watchers';
import Comments from 'components/Comment';
import Attachment from 'components/Attachment';
import AlertDialog from 'components/AlertDialog';
import LoaderSpinner from 'components/LoaderSpinner';
// import ManageDependency from 'pages/ManageDependency';

import { priority, status, event, Schema, select } from './helper';

import api from 'services/Api';
import {
  PATH_IAM_SERVICE,
  PATH_TICKET_SERVICE,
  PATH_UPLOAD_SERVICE,
  ROUTE_TICKETS,
} from '../../constants';

import useTickets from 'hooks/services/useTickets';
import useEntitlements from 'hooks/services/useEntitlements';
import useUsers from 'hooks/services/useUsers';
import { mapTickets, mapTicketsWithCustomerNames } from 'utils';
import useAllCustomers from 'hooks/services/useAllCustomers';

import styles from './styles';

const ManageTicket = () => {
  const classes = styles();
  const { path } = useRouteMatch();
  const location = useLocation().state;
  const history = useHistory();
  const { ticketId: id, entitlementId: entId } = useParams();
  const [ticketData, setTicketData] = useState(location?.ticketData);
  const [ticket, setTicket] = useState('');
  const [watchers, setWatchers] = useState([]);
  const [ticketComments, setTicketComments] = useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [warningDescriptipn, setWarningDescriptipn] = useState('');
  const [files, setFiles] = useState([]);
  const [entitlementName, setEntitlementName] = useState('');
  const [title, setTitle] = useState(ticketData ? ticketData?.title : '');
  const [name, setName] = useState(ticketData ? ticketData?.name : '');
  const [description, setDescription] = useState(
    ticketData ? ticketData?.summary : ''
  );
  const [entitlementNameArr, setEntitlementNameArr] = useState([]);
  const organizationId = useSelector(selectOrganization);
  const cachedAuth = useSelector(selectProfile);
  const selectedWatchers = [];
  const { data: entitlementNameData } = useEntitlements({
    entitlementId: entId || ticketData?.entitlementId,
  });
  const { data: activeUsers, isFetching } = useUsers({
    organizationId,
    userStatus: 'ACTIVE',
  });
  let [flattenedTickets, setFlattenedTickets] = useState([]);
  const { data: allTickets } = useTickets([entId], parseInt(id));
  let [tickets, setAllTickets] = useState([]);
  let [entitlement, setEntitlement] = useState([]);
  let [customer, setCustomer] = useState([]);
  const { data: entitlementsData } = useEntitlements({});
  const { data: customersData } = useAllCustomers();
  const combinedTickets = mapTickets(tickets, entitlement);
  const ticketsWithCustomerNames = mapTicketsWithCustomerNames(
    combinedTickets,
    customer
  );

  const ticketInfo = (ticketId) => {
    const ticket = ticketsWithCustomerNames?.find(
      (ticket) => String(ticket.ticketId) === ticketId
    );
    setTicketData(ticket);
  };

  const flattenedNamesData =
    entitlementNameData &&
    entitlementNameData.pages.reduce((acc, curr) => {
      return [...acc, ...curr.entitlements];
    }, []);

  const flattenedUsers =
    activeUsers &&
    activeUsers.pages.reduce((accum, curr) => {
      return [...accum, ...curr];
    }, []);

  const usersWithName =
    flattenedUsers &&
    flattenedUsers.map((user) => {
      const namedUser = {
        value: user.email,
        name: user.firstName + ' ' + user.lastName,
        id: user.id,
      };
      return namedUser;
    }, []);

  useEffect(() => {
    if (entitlementNameData) {
      const entitlements = flattenedNamesData?.map((entitlement) => {
        return {
          name: entitlement?.name,
          value: entitlement?.entitlementId,
        };
      });
      setEntitlementNameArr(entitlements);
    }
  }, [entitlementNameData]);

  useEffect(() => {
    if (entitlementNameArr.length > 0) {
      const data = entitlementNameArr?.find(
        (e) => (e.value = ticketData?.entitlementId || entId)
      );
      setEntitlementName(data?.value);
    }
  }, [entitlementNameArr]);

  useEffect(() => {
    if (!ticketData && allTickets) {
      const reducedflattenedTickets = allTickets?.pages?.reduce(
        (accum, curr) => [...accum, ...curr.tickets],
        []
      );
      const reducedflattenedEntitlements = entitlementsData?.pages?.reduce(
        (accum, curr) => [...accum, ...curr.entitlements],
        []
      );
      const reducedflattenedCustomers = customersData?.pages?.reduce(
        (accum, curr) => [...accum, ...curr.customers],
        []
      );

      setCustomer(reducedflattenedCustomers || []);
      setEntitlement(reducedflattenedEntitlements || []);
      if (reducedflattenedTickets) {
        setFlattenedTickets(reducedflattenedTickets);
        setAllTickets(reducedflattenedTickets);
      }
    }
  }, [allTickets, entitlementsData]);

  useEffect(() => {
    if (id && !ticketData && ticketsWithCustomerNames.length > 0) {
      ticketInfo(id);
    }
  }, [id, ticketInfo, ticketsWithCustomerNames]);

  const { register, handleSubmit, errors, getValues, setValue, formState } =
    useForm({
      resolver: yupResolver(Schema),
      mode: 'onBlur',
    });

  const { isDirty } = formState;

  const onSubmit = async (data) => {
    try {
      if (ticketData) {
        const existingTicket = {
          ...data,
        };
        const updateTicket = await api.put(
          `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}`,
          JSON.stringify(existingTicket)
        );

        const result = await updateTicket.json();
        setTicket([...ticket, result.data]);
      } else {
        const selectWatchers = selectedWatchers.filter(
          (watcher) => watcher.isChecked === true
        );
        selectWatchers.map((watcher) => delete watcher.isChecked);

        var uploadedFiles = new Array();

        for (const f of files) {
          const data = new FormData();
          data.append('file', f);
          try {
            const addFile = await api.post(
              `${PATH_UPLOAD_SERVICE}/v1/file`,
              data
            );
            const result = await addFile.json();
            const url = result.data.url;
            const file = url.substring(url.lastIndexOf('/') + 1);
            const fileArray = file.split('.');
            const fileName = fileArray[0];
            const fileType = fileArray[1];

            uploadedFiles.push({
              fileName,
              fileType,
              url,
            });
          } catch (error) {
            // Error not threated to avoid tickets not to be posted because of a S3 problem
            console.log(error);
          }
        }

        const newTicket = {
          ...data,
          watchers: selectWatchers,
          attachments: uploadedFiles,
        };

        const addTicket = await api.post(
          `${PATH_TICKET_SERVICE}/v1/tickets`,
          JSON.stringify(newTicket)
        );
        const result = await addTicket.json();
        setTicket([...ticket, result.data]);
      }
      history.goBack(`/${path}`);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleOk = () => {
    history.goBack(`/${path}`);
    handleAlertClose();
  };

  const changePriority = async (value) => {
    setValue('userPriority', value);
    if (ticketData) {
      try {
        await api.patch(
          `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/priority?ticket-priority=${value}`
        );
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  const changeEntitlementId = (value) => {
    setValue('entitlementId', value);
  };

  const changeStatus = async (value) => {
    let ticketEvent = event.find((i) => i.comboValue === value).value;
    if (ticketData) {
      try {
        await api.patch(
          `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/status?ticket-event=${ticketEvent}`
        );
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  const changeAssignee = async (value) => {
    if (ticketData) {
      const assigneeDTO = {
        userId: value,
      };
      try {
        await api.patch(
          `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/assignee`,
          JSON.stringify(assigneeDTO)
        );
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  useEffect(() => {
    if (ticketData && ticketData.title != title) {
      const callTicketService = async () => {
        try {
          await api.patch(
            `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData?.entitlementId}/${ticketData?.ticketId}/title?title=${title}`
          );
        } catch (error) {
          return Promise.reject(error);
        }
      };

      let debouncer = setTimeout(() => {
        callTicketService();
      }, 4000);

      return () => {
        clearTimeout(debouncer);
      };
    }
  }, [title]);

  useEffect(() => {
    if (ticketData && ticketData.summary != description) {
      const callTicketService = async () => {
        const dto = {
          description: description,
        };
        try {
          await api.patch(
            `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/description`,
            JSON.stringify(dto)
          );
        } catch (error) {
          return Promise.reject(error);
        }
      };

      let debouncer = setTimeout(() => {
        callTicketService();
      }, 4000);

      return () => {
        clearTimeout(debouncer);
      };
    }
  }, [description]);

  useEffect(() => {
    if (ticketData && files.length != 0) {
      const callTicketService = async () => {
        const data = new FormData();
        data.append('file', files.lastItem);
        try {
          await api.post(`${PATH_UPLOAD_SERVICE}/v1/file`, data);
        } catch (error) {
          return Promise.reject(error);
        }
      };

      let debouncer = setTimeout(() => {
        callTicketService();
      }, 4000);

      return () => {
        clearTimeout(debouncer);
      };
    }
  }, [files]);

  const handlePreClose = () => {
    const currentValues = getValues(['entitlementId', 'title', 'description']);
    const populatedValues = Object.values(currentValues).filter(
      (value) => value !== ''
    );
    if (populatedValues.length !== 0 && isDirty && !ticketData) {
      setOpenAlert(true);
      setWarningDescriptipn('Your unsaved data will be LOST!!!!');
    } else {
      history.push(ROUTE_TICKETS);
    }
  };

  useEffect(() => {
    const fetchWatchers = async () => {
      try {
        const headers = {
          states: 'ACTIVE',
          orgId: `${cachedAuth.attributes.orgId[0]}`,
        };
        const response = await api.get(`${PATH_IAM_SERVICE}/v1/users`, {
          headers,
        });
        const {
          data: { users },
        } = await response.json();
        return users;
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const fetchComments = async () => {
      if (ticketData) {
        try {
          const response = await api.get(
            `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData?.entitlementId}/${ticketData?.ticketId}/comments`
          );
          const {
            data: { comments },
          } = await response.json();
          return comments;
        } catch (error) {
          return Promise.reject(error);
        }
      }
    };

    Promise.all([fetchWatchers(), fetchComments()])
      .then(([watchers, comments]) => {
        if (watchers) {
          let watcherArr = watchers?.map((watcher) => {
            return {
              userName: watcher?.firstName + ' ' + watcher?.lastName,
              userId: watcher?.id,
            };
          });
          setWatchers(watcherArr);
        }
        if (comments) {
          setTicketComments(comments);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ticketData]);

  return (
    <>
      <AlertDialog
        buttonConfirmText="ok"
        buttonCancelText="close"
        title="Warning"
        open={openAlert}
        handleClose={handleAlertClose}
        handleOk={handleOk}
      >
        {warningDescriptipn}
      </AlertDialog>
      <Grid container>
        {ticketData ? (
          <>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={classes.headerContainer}>
                <IconButton
                  className={classes.backArrow}
                  onClick={handlePreClose}
                >
                  <ArrowBackIcon />
                </IconButton>

                <h1 className={classes.topicFont}>
                  {ticketData &&
                    `${ticketData['ticketDisplayId']}: ${ticketData['title']}`}
                </h1>
              </div>

              <Grid item>
                <Watchers
                  watchers={watchers}
                  selectedWatchers={ticketData && ticketData?.watchers}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Grid container>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={ticketData ? 6 : 12}
                    spacing={0}
                    className={classes.leftContainer}
                    alignContent="space-between"
                  >
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <p className={classes.subTopic}> Product or Service </p>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          style={{ background: '#FFFFFF' }}
                          disabled={ticketData ? true : false}
                        >
                          <CustomSelect
                            id="entitlementId"
                            name="entitlementId"
                            inputLabelId="entitlementId"
                            disabled={true}
                            error={errors?.entitlementId}
                            register={register}
                            defaultValue={entitlementName}
                            value={entitlementName}
                            selectItems={select(
                              entitlementNameArr ? entitlementNameArr : []
                            )}
                            onChange={(e) =>
                              changeEntitlementId(e.target.value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <p className={classes.subTopic}> Created By </p>
                        <TextField
                          className={classes.textField}
                          inputRef={register}
                          name="customerName"
                          placeholder="customerName"
                          margin="dense"
                          variant="outlined"
                          disabled={true}
                          error={errors.name ? true : false}
                          defaultValue={ticketData ? ticketData?.name : ''}
                          onChange={(e) => setName(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    {ticketData && (
                      <Grid item container spacing={2}>
                        <Grid item xs={4}>
                          <p className={classes.subTopic}> Status </p>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ background: '#FFFFFF' }}
                          >
                            <CustomSelect
                              id="status"
                              name="status"
                              inputLabelId="status"
                              disabled={false}
                              defaultValue={
                                ticketData ? ticketData?.status : ''
                              }
                              register={register}
                              selectItems={select(status)}
                              onChange={(e) => changeStatus(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <p className={classes.subTopic}> Priority </p>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ background: '#FFFFFF' }}
                          >
                            <CustomSelect
                              id="userPriority"
                              name="userPriority"
                              inputLabelId="userPriority"
                              disabled={true}
                              defaultValue={
                                ticketData ? ticketData?.userPriority : `LOW`
                              }
                              register={register}
                              selectItems={select(priority)}
                              onChange={(e) => changePriority(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <p className={classes.subTopic}> Assignee </p>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ background: '#FFFFFF' }}
                          >
                            <CustomSelect
                              variant="filled"
                              id="assigneeId"
                              name="assigneeId"
                              inputLabelId="assigneeId"
                              disabled={true}
                              defaultValue={
                                ticketData ? ticketData?.assigneeEmail : ''
                              }
                              register={register}
                              selectItems={usersWithName ? usersWithName : []}
                              onChange={(e) => changeAssignee(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item className={classes.gridItem}>
                      <p className={classes.subTopic}> Title </p>
                      <TextField
                        className={classes.textField}
                        inputRef={register}
                        name="title"
                        placeholder="Enter Title"
                        margin="dense"
                        variant="outlined"
                        disabled={true}
                        error={errors.title ? true : false}
                        defaultValue={ticketData ? ticketData?.title : ''}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                      <p className={classes.subTopic}> Description </p>

                      <TextField
                        className={classes.textField}
                        inputRef={register}
                        name="description"
                        placeholder="Enter Description"
                        margin="dense"
                        multiline
                        rows={10}
                        disabled={true}
                        variant="outlined"
                        defaultValue={ticketData ? ticketData?.description : ''}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item className={classes.gridItem}></Grid>
                    <Grid item className={classes.gridItem}>
                      <Attachment
                        passFiles={setFiles}
                        attachments={ticketData && ticketData?.attachments}
                        ticketData={ticketData}
                      />
                    </Grid>
                  </Grid>
                  {ticketData && (
                    <Grid
                      item
                      container
                      md={6}
                      xs={12}
                      className={classes.commentsContainer}
                      style={{ marginTop: '10px' }}
                    >
                      <Comments
                        ticketData={ticketData}
                        ticketComments={ticketComments}
                        xs={12}
                      />
                    </Grid>
                  )}
                </Grid>
              </form>
            </Grid>
          </>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.spinner}
          >
            <LoaderSpinner type="Oval" color="#00BFFF" height={60} width={60} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ManageTicket;
