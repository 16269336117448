import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import api from 'services/Api';
import { PATH_TICKET_SERVICE, PATH_UPLOAD_SERVICE } from '../../constants';
import { Dialog, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
    // If image is needed to be previewed
    // '&:hover': {
    //   position: 'absolute',
    // }
  },
  add: {
    display: 'flex',
    flexDirection: 'inherit',
    alignItems: 'center',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: '8vh',
    height: '8vh',
    padding: 4,
    boxSizing: 'border-box',
    '&:hover $deleteButton': {
      position: 'absolute',
      opacity: 1,
    },
    '&:hover': {
      opacity: '0.7',
    },
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '9vh',
    overflowY: 'auto',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  deleteButton: {
    display: 'hidden',
    opacity: 0,
    transition: '.5s ease',
    position: 'absolute',
  },
  heading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  attachTopic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: '#0F5EF7',
  },
}));

const Attachment = ({ passFiles, attachments, ticketData }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [showImage, setShowImage] = useState(false);
  const [imageToShow, setImageToShow] = useState('');

  useEffect(() => {
    if (attachments) {
      setIsEdit(true);
    }
  }, [attachments]);

  useEffect(() => {
    let exec = async () => {
      if (attachments) {
        let attach = await Promise.all(
          attachments.map(async (attachment) => {
            return {
              id: attachment.attachmentId,
              name: attachment.fileName,
              url: attachment.url,
              type: attachment.fileType,
            };
          })
        );
        setFiles(attach);
      }
    };
    exec();
  }, [attachments]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      for (let acceptedFile of acceptedFiles) {
        if (!isEdit) {
          passFiles(files.concat(acceptedFile));
          setFiles(
            files.concat({
              id: '',
              name: acceptedFile.name,
              url: URL.createObjectURL(acceptedFile),
              type: acceptedFile.type,
            })
          );
        } else {
          acceptedFiles.map(async (acceptedFile) => {
            const response = await uploadFileToS3Bucket(acceptedFile);
            const attachmentId = await saveFileInfoToTicketService(response);
            setFiles(
              files.concat({
                id: attachmentId,
                name: response.fileName,
                url: response.url,
                type: response.fileType,
              })
            );
          });
        }
      }
    },
    multiple: true,
  });

  const saveFileInfoToTicketService = async (uploadServiceResponse) => {
    try {
      const addAttachment = await api.post(
        `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/attachments`,
        JSON.stringify(uploadServiceResponse)
      );
      const result = await addAttachment.json();
      return result.data.attachment.attachmentId;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const uploadFileToS3Bucket = async (f) => {
    const data = new FormData();
    data.append('file', f);
    try {
      const addFile = await api.post(`${PATH_UPLOAD_SERVICE}/v1/file`, data);
      const result = await addFile.json();
      const url = result.data.url;
      const file = url.substring(url.lastIndexOf('/') + 1);
      const fileArray = file.split('.');
      const fileName = fileArray[0];
      const fileType = fileArray[1];

      return {
        fileName,
        fileType,
        url,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const removeImg = (fileName, fileType, fileId) => {
    const filteredFiles = files.filter((file) => file.name !== fileName);
    if (!isEdit) {
      passFiles(filteredFiles);
    } else {
      removeFileFromS3Bucket(`${fileName}.${fileType}`);
      deleteFileInfoFromTicketService(fileId);
    }
    setFiles(filteredFiles);
  };

  const removeFileFromS3Bucket = async (file) => {
    try {
      await api.delete(`${PATH_UPLOAD_SERVICE}/v1/file/${file}`);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteFileInfoFromTicketService = async (id) => {
    await api.delete(
      `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/attachments/${id}`
    );
  };

  const closeImage = () => {
    setShowImage(false);
  };

  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <Tooltip title="Click to enlarge">
        <div className={classes.thumbInner}>
          <img
            src={file.url}
            className={classes.img}
            alt={file.name}
            onClick={() => {
              setShowImage(true);
              setImageToShow(file.url);
            }}
            role="button"
          />
        </div>
      </Tooltip>
      <div
        className={classes.deleteButton}
        onClick={() => removeImg(file.name, file.type, file.id)}
      >
        <DeleteIcon style={{ stroke: 'white' }} />
      </div>
    </div>
  ));

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <>
      <span>
        <Dialog open={showImage} onClose={closeImage} maxWidth="xl">
          <img
            src={imageToShow}
            onClick={() => {
              closeImage();
            }}
          />
        </Dialog>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <span className={classes.heading}>Attachments</span>
          </Grid>
          <Grid item>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <div className={classes.add}>
                <span className={classes.attachTopic} size="small">
                  Add Attachment
                </span>
                <AddCircleOutlineRoundedIcon style={{ color: '#0F5EF7' }} />
              </div>
            </div>
          </Grid>
        </Grid>
      </span>

      <div className={classes.thumbsContainer}>{thumbs}</div>
    </>
  );
};

export default Attachment;
