import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TicketStatus from 'schema/ticket-status';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: props.statusColor,
    fontFamily: 'Nunito',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    textTransform: 'capitalize',
    lineHeight: '18px',
  }),
}));

const priorityMap = {
  [TicketStatus.OPENED.enumKey]: {
    statusColor: 'rgba(65, 152, 253, 1)',
  },
  [TicketStatus.IN_PROGRESS.enumKey]: {
    statusColor: 'rgba(236, 173, 10, 1)',
  },
  [TicketStatus.IN_REVIEW.enumKey]: {
    statusColor: 'rgba( 0,188,23,1)',
  },
  [TicketStatus.ON_HOLD.enumKey]: {
    statusColor: 'rgba(252, 45, 45, 1)',
  },
  [TicketStatus.CLOSED.enumKey]: {
    statusColor: 'rgba( 0,80,139,1)',
  },
};

const StatusText = ({ priority }) => {
  const classes = useStyles(priorityMap[priority]);

  switch (priority) {
    case TicketStatus.OPENED.enumKey:
      return (
        <span className={classes.root}>{TicketStatus.OPENED.displayValue}</span>
      );
    case TicketStatus.IN_PROGRESS.enumKey:
      return (
        <span className={classes.root}>
          {TicketStatus.IN_PROGRESS.displayValue}
        </span>
      );
    case TicketStatus.IN_REVIEW.enumKey:
      return (
        <span className={classes.root}>
          {TicketStatus.IN_REVIEW.displayValue}
        </span>
      );
    case TicketStatus.CLOSED.enumKey:
      return (
        <span className={classes.root}>{TicketStatus.CLOSED.displayValue}</span>
      );
    case TicketStatus.ON_HOLD.enumKey:
      return (
        <span className={classes.root}>
          {TicketStatus.ON_HOLD.displayValue}
        </span>
      );
    default:
      return null;
  }
};

export default StatusText;
